.paymentForm {
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0;
}

.paymentMethod {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
}
