.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  box-shadow: inset 0 -1px 0 #e7e8ea;
  cursor: pointer;

  &:last-child {
    box-shadow: none;
  }

  &.highlighted {
    .number,
    .payment {
      color: #ff5151;
    }
  }
}

.info {
  display: flex;
  gap: 10px;
}

.number {
  display: flex;
  color: #2e292b;
  font-size: 14px;
  justify-content: space-between;
}

.type {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  padding-left: 12px;

  img {
    margin-left: 2px;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  color: #6c707a;
  font-size: 16px;
  align-items: flex-end;
}
